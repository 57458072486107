/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

import { startStimulusApp } from '@symfony/stimulus-bridge';

export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!./controllers',
    true,
    /\.(j|t)sx?$/
));
// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
import './styles/select2.css';

// loads the jquery package from node_modules
import 'jquery';


// start the Stimulus application
import './bootstrap';
